
  <template>
  <!--    <v-layout row mb-3 wrap fluid pt-0 mt-0>
        <v-flex xs12 md4 mb-4 v-for="card in cards" :key="card.title" pt-0 mt-0>
          <v-card height="100%" class="flexcard">                       
              <v-card-text class="grow text-xs-center text-sm-left grey--text">
                <v-button @click="setTheme">Test</v-button>
                </v-card-text>
          </v-card>
        </v-flex>
  </v-layout>-->
  <v-layout row wrap>
    <v-card>
      <v-card-text class="text-xs-center text-sm-left grey--text">
       Please select the main colour of the website based on your personal preference.
      </v-card-text>
      <v-divider></v-divider>            
      <v-card-text ma-0 pa-0 class="text-xs-center grey--text">
        <v-flex>
        <v-btn
      @click="setTheme('#BBDEFB')"
      color="#BBDEFB"
      fab
      small
      class="grey--text text--darken-4 pa-0"
    ></v-btn>
    <v-btn
      @click="setTheme('#9CCC65')"
      color="#9CCC65"
      fab
      small
      class="grey--text text--darken-4 pa-0"
    ></v-btn>
    <v-btn
      @click="setTheme('#FFEE58')"
      color="#FFEE58"
      fab
      small
      class="grey--text text--darken-4 pa-0"
    ></v-btn>
    <v-btn
      @click="setTheme('#A7FFEB')"
      color="#A7FFEB"
      fab
      small
      class="grey--text text--darken-4 pa-0"
    ></v-btn>
    </v-flex>
    <v-flex>
    <v-btn
      @click="setTheme('#FF5252')"
      color="#FF5252"
      fab
      small
      class="grey--text text--darken-4 pa-0"
    ></v-btn>
    <v-btn
      @click="setTheme('#FFA726')"
      color="#FFA726"
      fab
      small
      class="grey--text text--darken-4 pa-0"
    ></v-btn>
    <v-btn
      @click="setTheme('#A1887F')"
      color="#A1887F"
      fab
      small
      class="grey--text text--darken-4 pa-0"
    ></v-btn>
    <v-btn
      @click="setTheme('#BA68C8')"
      color="#BA68C8"
      fab
      small
      class="grey--text text--darken-4 pa-0"
    ></v-btn>
    </v-flex>
      </v-card-text>
    </v-card>

   
  </v-layout>
</template>

<script>
// import { db } from "@/fb";
export default {
  metaInfo: {
    titleTemplate: "%s | Settings"
  },
  methods: {
    setTheme(color) {
      this.$store.dispatch("togglePrimary", color);
    }
  },
  data: () => ({})
};
</script>



